import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { size: Number }

  connect() {
    $(document).on("cocoon:before-insert", (event) => {
      const el = $('.nested-fields')

      if (el.length >= this.sizeValue) {
        el.first().before(`
          <div class='alert alert-danger my-2' role='alert'>
            ファイルは5個までアップロードできます
          </div>
        `).prev().delay(3000).slideUp()

        event.preventDefault()
        event.stopImmediatePropagation()
      }
    })
  }
}
