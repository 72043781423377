import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    const el = $('#admin_projects_table')

    el.dataTable({
      language: {
        url: "/datatables.ja.json"
      },
      pageLength: 25,
      processing: true,
      serverSide: true,
      ajax: el.data('source'),
      pagingType: 'full_numbers',
      columns: [
        { data: 'id' },
        { data: 'uid' },
        { data: 'name' },
        { data: 'show', 'orderable': false },
        { data: 'destroy', 'orderable': false }
      ]
    })
  }
}
