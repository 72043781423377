import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    $(this.element).chosen({
      disable_search_threshold: 10
    })
  }

  disconnect() {
    $(this.element).chosen("destroy")
  }
}
