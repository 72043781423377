import "core-js/stable";
import "regenerator-runtime/runtime";

// Rails
import Rails from "@rails/ujs";
Rails.start();

// Hotwired
import "@hotwired/turbo-rails";
import "controllers";

// ActionCable
import "channels";

// jQuery
import $ from "jquery";
global.$ = global.jQuery = $;

// Bootstrap
import "bootstrap";
import "./lib/bs_components";

// Font Awesome
import "@fortawesome/fontawesome-free/js/all";

// Cocoon
require("@nathanvda/cocoon");
import "./lib/cocoon_patch";

// Datatables
require("datatables.net-bs4")(window, $);

// Chosen
import "chosen-js";

// Stylesheet
import "../stylesheets/application.scss";

// Images
const images = require.context("..//images", true); // eslint-disable-line no-unused-vars
// const imagePath = (name) => images(name, true); // eslint-disable-line no-unused-vars
