import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  checkAll(event) {
    const check = event.target.checked
    document.querySelectorAll('*[id^="sv_reports_using_"]').forEach(el => el.checked = check)
  }

  checkRequired(event) {
    const check = event.target.checked
    document.querySelectorAll('*[id^="sv_reports_using_"]').forEach(el => {
      if (el.nextSibling.innerText.includes("(指定検査)")) {
        el.checked = true
      } else {
        el.checked = false
      }
    })
  }
}
