import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  formCancel(event) {
    const post_comment = $(event.target).closest('[id^=post_comment_]')
    const base_link = `${window.location.pathname}/post_comments/${post_comment.data('post-comment-id')}`

    // デフォルトで表示されるコメントフォームに値を追加しないように分岐する
    // 実際の処理には影響ないと思うけども、コンソールにはエラーが表示されるハズ
    if ($(event.target).data('reply') === true) {
      const reply_link = post_comment.find('[id^=reply_post_comment_link_]')[0]

      if (typeof reply_link !== 'undefined') {
        reply_link.href = `${base_link}/reply`
      }
    }

    const edit_link = post_comment.find('[id^=edit_post_comment_link_]')[0]
    if (typeof edit_link !== 'undefined') {
      edit_link.href = `${base_link}/edit`
    }

    $(event.target).closest('form').remove()
  }
}
