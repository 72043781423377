import { Controller } from "@hotwired/stimulus";

import Uppy from'@uppy/core'
import XHRUpload from '@uppy/xhr-upload'
import Dashboard from '@uppy/dashboard'
import Japanese from '@uppy/locales/lib/ja_JP'

import "@uppy/core/dist/style.css"
import "@uppy/dashboard/dist/style.css"

export default class extends Controller {
  static values = { reportId: Number, reportName: String, reqImageCount: Number, curImageCount: Number, uploadUrl: String }

  connect() {
    const uppyElement = Uppy({
      id: `report_${this.reportIdValue}`,
      autoProceed: false,
      restrictions: {
        maxFileSize: 10 * 1024 * 1024,
        maxNumberOfFiles: 5 - this.curImageCountValue,
        allowedFileTypes: ['image/*'],
      },
      locale: Japanese,
    })

    uppyElement.use(Dashboard, {
      inline: false,
      trigger: `.uppy-toggle_${this.reportIdValue}`,
      note: `あと${5 - this.curImageCountValue}枚までアップロードできます`,
      closeModalOnClickOutside: true,
      proudlyDisplayPoweredByUppy: false,
      showLinkToFileUploadResult: false,
      locale: {
        strings: {
          dropPaste: '写真のアスペクト比は横4:縦3にして下さい 写真は横方向で撮影してください %{browse}',
          browse: '写真を選択/撮影する',
        }
      }
    })

    uppyElement.use(XHRUpload, {
      endpoint: this.uploadUrlValue,
      formData: true,
      fieldName: 'file',
      limit: 1,
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') }
    }).on('upload-success', (file, response) => {
      const imageId = response.body['image_id']
      const imageCount = response.body['image_count']
      const imageExif = response.body['exif']
      const imageCreatedAt = response.body['created_at']
      const result = response.body['result']

      const target = $(`*[data-report-id=${this.reportIdValue}] .row`)
      const imagesField = $(`#sv_report_images_${this.reportIdValue}`)

      // Upload button
      if (imageCount >= 5) {
        $(`*[data-report-id=${this.reportIdValue}] .upload-btn`).remove()
      }

      // Pic counter
      target.find('.col-md-10').eq(0).empty()
      target.find('.col-md-10').eq(0).text(`${imageCount} / ${this.reqImageCountValue}`)

      // Result
      if (imageCount >= this.reqImageCountValue) {
        if (result === 'unconfirmed') {
          target.find('.col-md-10').eq(3).empty().removeClass('text-danger')
          target.find('.col-md-10').eq(3).html('<i class="far fa-circle text-muted"></i> 施工前')
        }
      } else {
        target.find('.col-md-10').eq(3).empty()
        target.find('.col-md-10').eq(3).html(`<span>あと<b>${this.reqImageCountValue - imageCount}枚</b>以上の写真が必要です。</span>`).addClass('text-danger')
      }

      // Image
      let img = new Image()
      img.classList.add('img-thumbnail', 'my-2')
      img.src = response.uploadURL

      let info = `
          <div class="clearfix">
            <div class="float-left">
              <dl class="mb-0">
                <dt>撮影日</dt>
                <dd>${imageExif}</dd>
                <dt>登録日</dt>
                <dd class="mb-0">${imageCreatedAt}</dd>
              </dl>
            </div>
            <div class="float-right">
              <a
                href="/sv/images/${imageId}"
                class="btn btn-sm btn-outline-danger sv_image_delete"
                data-delete-button-id="${imageId}"
                data-confirm="削除しますか?"
                data-remote="true"
                data-method="delete"
                rel="nofollow"
              ><i class="far fa-trash-alt"></i> 削除</a>
            </div>
        `
      imagesField.append($('<div/>').addClass('col-xl-3 col-lg-4 col-sm-6').attr('data-image-id', imageId).append(img).append(info))
    })

    uppyElement.run()
  }
}
