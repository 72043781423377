import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    const el = $('#admin_gb_users_table')

    el.dataTable({
      language: {
        url: '/datatables.ja.json'
      },
      pageLength: 25,
      processing: true,
      serverSide: true,
      ajax: el.data('source'),
      pagingType: 'full_numbers',
      columns: [
        { data: 'id' },
        { data: 'uid' },
        { data: 'login' },
        { data: 'password' },
        { data: 'formal_name', 'orderable': false },
        { data: 'last_name', 'orderable': false },
        { data: 'first_name', 'orderable': false },
        { data: 'created_at', 'orderable': false },
        { data: 'updated_at', 'orderable': false },
        { data: 'link', 'orderable': false }
      ]
    })
  }
}
