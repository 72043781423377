import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  openAll() {
    $('[id^=c_project_]').each((index, el) => {
      $(`#${el.id}`).collapse('show')
    })
  }

  closeAll() {
    $('[id^=c_project_]').each((index, el) => {
      $(`#${el.id}`).collapse('hide')
    })
  }
}
