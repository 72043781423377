import { Controller } from "@hotwired/stimulus";

const reader = new FileReader()

export default class extends Controller {
  connect() {
    reader.addEventListener("load", _ => this.handleFile())
  }

  disconnect() {
    reader.removeEventListener("load", _ => this.handleFile())
  }

  confirm(event) {
    if (event.target.checked) {
      $('input[name="commit"]').removeClass('d-none')
    } else {
      $('input[name="commit"]').addClass('d-none')
    }
  }

  selectFile(event) {
    reader.readAsText(event.target.files[0])
  }

  handleFile() {
    const json = $.parseJSON(reader.result)
    const prev = $('#preview > ul').eq(0).empty()
    const prev_summary = $('#preview_summary > ul').eq(0).empty()

    let p = 0
    let c = 0
    let g = 0

    Object.keys(json.datas).forEach((parent) => {
      $(`<span>${parent}<br></span>`).addClass('text-danger').appendTo(prev)
      p += 1

      Object.keys(json.datas[parent]).forEach((child) => {
        $(`<span>　- ${child}<br></span>`).addClass('text-success').appendTo(prev)
        c += 1

        Object.keys(json.datas[parent][child]).forEach((grandchild) => {
          $(`<span>　　- ${grandchild} : ${json.datas[parent][child][grandchild]}枚<br></span>`).addClass('text-primary').appendTo(prev)
          g += 1
        })
      })
    })

    $(`<li>小項目数：${g}</li>`).addClass('text-primary').prependTo(prev_summary)
    $(`<li>中項目数：${c}</li>`).addClass('text-success').prependTo(prev_summary)
    $(`<li>大項目数：${p}</li>`).addClass('text-danger').prependTo(prev_summary)
    $(`<li>構造ID：${json.info.structure_id}</li>`).prependTo(prev_summary)
    $(`<li>テンプレ名：${json.info.name}</li>`).prependTo(prev_summary)
  }
}
