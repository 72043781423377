import { Controller } from "@hotwired/stimulus";

import "daterangepicker";
import moment from 'moment/src/moment';

export default class extends Controller {
  connect() {
    const el = $('input[name="dates"]')

    el.daterangepicker({
      ranges: {
        '今日': [moment(), moment()],
        '昨日': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        '過去7日間': [moment().subtract(6, 'days'), moment()],
        '過去30日間': [moment().subtract(29, 'days'), moment()],
        '今月': [moment().startOf('month'), moment().endOf('month')],
        '先月': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      },
      locale: {
        format: 'YYYY/MM/DD',
        applyLabel: '保存',
        cancelLabel: 'クリア',
        customRangeLabel: '日付を選択',
        daysOfWeek: ['日', '月', '火', '水', '木', '金', '土'],
        monthNames: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']
      },
      showCustomRangeLabel: false,
      alwaysShowCalendars: true,
      autoUpdateInput: false
    })

    el.on('apply.daterangepicker', (_, picker) => {
      $(el).val(`${picker.startDate.format('YYYY/MM/DD')} - ${picker.endDate.format('YYYY/MM/DD')}`)
    })

    el.on('cancel.daterangepicker', (_, picker) => {
      $(el).val('')
    })
  }
}
